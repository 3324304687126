<template>
    <div class="">
        <b-modal content-class="modal-fullscreen" id="modal-confirm"
                 centered
                 scrollable
                 no-close-on-backdrop
                 hide-footer
                 hide-header>
            <div class="">


                <div class="card bg-transparent text-center">
                    <div class="card-body ">
                        <img class="icon mb-3" src="../assets/images/fail.png">


                        <div class="row justify-content-center">
                            <div class="col-12 col-sm-auto message-body">
                                <div v-html="data.title"></div>
                                <div v-html="data.message"></div>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="row no-gutters">
                    <div class="col-4">
                        <div class="btn btn-secondary w-100 no-radius btn-lg" @click="close">
                            CANCEL
                        </div>
                    </div>
                    <div class="col">
                        <div class="btn btn-primary w-100 no-radius btn-lg" @click="confirm">
                            OK
                        </div>
                    </div>
                </div>

            </div>


        </b-modal>
    </div>
</template>

<script>
    export default {
        name: 'ConfirmDialog',
        props: {
            data: Object,
        },
        data() {
            return {
                isLoading: false,
            }
        },
        methods: {
            async close() {
                this.$bvModal.hide('modal-confirm');
            },
            async confirm() {
                this.$bvModal.hide('modal-confirm');
                window.location.href = "https://mket-2fa.stockradars.co/cancel2fa";
            }
        }
    }
</script>

<style lang="scss">
    @import "../assets/css/colors";

    #modal-confirm .modal-body {
        background-color: $black !important;
        padding: 0;
    }

    .modal-backdrop {
        background-color: rgba(0, 0, 0, 0.7) !important;
    }

    #modal-confirm .modal-content {
        background: transparent;
    }

    #modal-confirm .message-body {
        max-width: 400px;
    }

</style>
