<template>
    <div class="container">
        <div class="row align-items-center justify-content-center" style="min-height: 100vh; margin-bottom: 50px">
            <div class="col-12 col-sm-auto text-center mb-3 mt-3" style="max-width: 400px">
                <div class="mb-3">
                    ดำเนินการปกป้องบัญชีของท่าน <br>
                    ด้วยการยืนยันตัวตนแบบ 2 ขั้นตอน
                </div>


                <img class="icon mb-3" src="../assets/images/otp.png">


                <div class="mb-5">
                    กรอกรหัส OTP จาก SMS ที่ส่งไปยังเบอร์มือถือ
                    {{phone}} (ref. code: {{otpRefCode}})

                </div>


                <div class="otp-container mb-5">
                    <v-otp-input
                            ref="otpInput"
                            input-classes="otp-input"
                            separator=""
                            :num-inputs="6"
                            :should-auto-focus="true"
                            :is-input-num="true"
                            @on-complete="handleOnComplete"
                    />
                </div>


                <div class="row justify-content-center">
                    <div class="col-auto">
                        <ul class="mb-5 text-left p-0">
                            <li>รหัส OTP จะหมดอายุภายใน {{minutes}}:{{seconds}}
                            </li>
                            <li>หากไม่ได้รับรหัส OTP กด <u @click="resend" class="pointer"
                                                           v-bind:class="{ 'text-secondary'  : disableResend }">ส่งอีกครั้ง</u>
                            </li>
                        </ul>
                    </div>
                </div>


                <!--<div class="mb-5">-->
                <!--<u class="pointer"> Update phone number </u>-->
                <!--</div>-->


                <div>
                    Remark: Change phone number <br>
                    Please contacts Maybank Securities <br>
                    Call Center Tel: 02-658-5050
                </div>


                <div class="row no-gutters fixed-bottom">
                    <div class="col-4">
                        <div class="btn btn-secondary w-100 no-radius btn-lg" @click="cancel">
                            CANCEL
                        </div>
                    </div>
                    <div class="col">
                        <div class="btn btn-primary w-100 no-radius btn-lg" @click="verify">
                            VERIFY
                        </div>
                    </div>
                </div>


                <confirm-dialog :data="confirmDialog"/>
                <message-dialog :data="messageDialog"/>
            </div>
        </div>

    </div>
</template>

<script>

    import ConfirmDialog from "../components/Confirm";
    import MessageDialog from "../components/Message";

    import {mapState} from 'vuex';
    import {
        VERIFY_OTP, GET_OTP
    } from "../store/actions.type";
    import store from '../store';

    export default {
        name: 'Verify',
        components: {MessageDialog, ConfirmDialog},
        computed: {
            ...mapState({
                user: state => state.otp.user,
                otpRefCode: state => state.otp.otpRefCode,
                phone: state => state.otp.phone,
            }),
        },
        data() {
            return {
                otp: "",
                isLoading: false,
                messageDialog: {},
                confirmDialog: {},
                minutes: "00",
                seconds: "00",
                countdown: null,
                disableResend: true
            }
        },
        created() {
            this.init();

            setTimeout(() => {
                this.disableResend = false;
            }, 30000);
        },
        methods: {
            init() {
                let countDownDate = new Date();
                countDownDate.setMinutes(countDownDate.getMinutes() + 5);

                this.countdown = setInterval(async function () {
                    let now = new Date().getTime();
                    let distance = countDownDate - now;

                    this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                    this.seconds = Math.floor((distance % (1000 * 60)) / 1000);

                    this.minutes = this.minutes < 10 ? "0" + this.minutes : this.minutes;
                    this.seconds = this.seconds < 10 ? "0" + this.seconds : this.seconds;

                    if (distance < 0) {
                        clearInterval(this.countdown);
                        this.minutes = "00";
                        this.seconds = "00";

                        // await this.$router.push('/error?type=expired');
                    }
                }.bind(this), 1000);
            },
            handleOnComplete(value) {
                this.otp = value;
            },
            async verify() {

                if (this.otp.length !== 6) return;

                if (this.isLoading) return;
                this.isLoading = true;

                let response = await store.dispatch(VERIFY_OTP, {
                    "uToken": this.user.uToken,
                    "userId": this.user.userId,
                    "deviceId": this.user.deviceId,
                    "deviceName": this.user.deviceName,
                    "otp": this.otp,
                    "otpRefCode": this.otpRefCode
                });

                this.isLoading = false;

                if (response.data) {
                    clearInterval(this.countdown);
                    await this.$router.push('/success');
                } else {

                    if (response.messageCode === 'OTP is not valid') {
                        this.messageDialog = {
                            type: "error",
                            title: `<div class="mb-3">
                        Fail to verify
                      </div>`,
                            message: `<div class="text-secondary">
                                    ท่านกรอกรหัส OTP ไม่ถูกต้อง โปรดลองอีกครั้ง
                                </div>
                        </div>`
                        };
                    }

                    this.$bvModal.show('modal-message');
                }


            },

            cancel() {
                this.confirmDialog = {
                    type: "error",
                    title: `<div class="mb-3">
                        Leaving 2FA process
                      </div>`,
                    message: `<div class="text-secondary">
                                   ท่านต้องการออกจากการยืนยันตัวตนแบบ 2 ขั้นตอนหรือไม่ เพื่อดำเนินการต่อให้สำเร็จ ขอแนะนำให้กด "CANCEL"
                                </div>
                        </div>`
                };

                this.$bvModal.show('modal-confirm');
            },

            async resend() {
                if (this.disableResend) return;
                if (this.isLoading) return;
                this.isLoading = true;

                clearInterval(this.countdown);

                await store.dispatch(GET_OTP, {
                    "uToken": this.user.uToken,
                    "userId": this.user.userId,
                    "deviceId": this.user.deviceId,
                    "deviceName": this.user.deviceName,
                });

                this.$refs.otpInput.clearInput();
                this.isLoading = false;
                this.disableResend = true;

                this.init();

                setTimeout(() => {
                    this.disableResend = false;
                }, 30000);
            }
        }
    }
</script>


<style lang="scss">
    @import "../assets/css/colors";

    .icon {
        max-height: 100px;
    }

    .otp-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .otp-input {
        width: 40px;
        // height: 40px;
        padding: 5px;
        margin: 0 5px;
        // font-size: 28px;
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.3);
        text-align: center;
        background-color: #313131;
        color: $white;

        &.error {
            border: 1px solid red !important;
        }
    }

    .otp-input::-webkit-inner-spin-button,
    .otp-input::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
</style>
